html,
body {
  width: 100%;
  height: 100%;
}

body {
  overflow: auto;
}

/* ทำให้ใช้งาน \n ในเว็บได้ */
body * {
  white-space: pre-wrap;
}

#root {
  display: flex;
  height: 100%;
}

::-webkit-scrollbar {
  background-color: rgba(250, 250, 250, 1);
  width: 16px;
}

::-webkit-scrollbar-track {
  background-color: rgba(250, 250, 250, 1);
  border: 1px solid rgba(231, 231, 231, 1);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(194, 194, 194, 1);
  border-radius: 16px;
  border: 4px solid transparent;
  background-clip: content-box;
}

body::-webkit-scrollbar-button {
  display: none;
}

body {
  caret-color: #f46938;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

body.fontLoaded {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

#app {
  background-color: #fafafa;
  min-height: 100%;
  min-width: 100%;
}

p,
label {
  font-family: Georgia, Times, 'Times New Roman', serif;
  line-height: 1.5em;
}

a:-webkit-any-link {
  color: #f46938;
  text-decoration: none !important;
}

input {
  background-color: #fcd9c7;
}

.bg-primary {
  background-color: #f46938;
}

.bg-secondary {
  background-color: #fcd9c7;
}

.txt-primary {
  color: #f46938;
}

.txt-active-dark {
  color: #eaeaea;
}

.txt-secondary {
  color: #fcd9c7;
}

.react-datepicker-wrapper {
  width: 100%;
}


/* แก้ปัญหา calendar โผล่ใต้ overlay  */
.react-datepicker-popper {
  z-index: 99999  !important;
}

/* ปุ่มวันที่ ของ date-picker */
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  color: #f46938 !important;
  background-color: #fcd9c7 !important;
}

/* ปุ่มเวลา ของ date-picker */
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  color: #f46938 !important;
  background-color: #fcd9c7 !important;
}

.image-gallery-fullscreen-button::before,
.image-gallery-play-button::before,
.image-gallery-left-nav::before,
.image-gallery-right-nav::before,
.image-gallery-icon:hover {
  color: #f46938 !important;
}

.image-gallery-fullscreen-button:hover::before,
.image-gallery-play-button:hover::before,
.image-gallery-left-nav:hover::before,
.image-gallery-right-nav:hover::before {
  color: #fcd9c7 !important;
}

.image-gallery-index {
  /* color: #f46938 !important; */
  font-size: 16px !important;
  font-family: 'Prompt-Regular' !important;
  /* background-color: transparent !important; */
}

.image-gallery-slides {
  /* color: #f46938 !important; */
  /* background-color: white !important; */
  /* background-color: rgba(0, 0, 0, 0.1) !important; */
  /* opacity: 0.5; */
  background-color: rgba(0, 0, 0, 0.9) !important;
}


button.image-gallery-icon.image-gallery-left-nav, button.image-gallery-icon.image-gallery-right-nav {
  height: 100%;
  /* background-color: rgba(194, 194, 194, 0.2) !important; */
  background-color: transparent !important;
  /* body > div:nth-child(14) > div > div > div:nth-child(2) > div > div > div > button.image-gallery-icon.image-gallery-right-nav */
}

button.image-gallery-icon.image-gallery-left-nav:hover, button.image-gallery-icon.image-gallery-right-nav:hover {
  background-color: rgba(194, 194, 194, 0.2) !important;
  /* body > div:nth-child(14) > div > div > div:nth-child(2) > div > div > div > button.image-gallery-icon.image-gallery-right-nav */
}

/* แก้ปัญหา numOfLines ใช้งานไม่ได้ */
div[class*=css-textMultiLine] {
  /* white-space: pre; */
  display: -webkit-box;
}

[class^=react-confirm] {
  font-family: 'Prompt-Regular' !important;
}

.react-confirm-alert-body {
  white-space: pre-wrap;
  font-size: 16px !important;
}

.react-confirm-alert-body > h1 {
  white-space: pre-wrap;
  font-size: 18px !important;
}

.react-confirm-alert-button-group > button {
  background-color: #f46938 !important;
  font-size: 14px !important;
  font-family: 'Prompt-Bold' !important;
  font-weight: bold;
}

.react-datepicker {
  font-size: 16px !important;
  font-family: 'Prompt-Regular' !important;
}

/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container, .Toastify__toast-body {
  font-family: 'Prompt-Regular' !important;
}

/* width */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 2px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

/* Tooltip text */
.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 999;
  top: -5px;
  left: 105%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
